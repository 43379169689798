<template>
  <div>
    <v-card class="radius_intab custom_card pa-5">
      <div class="toolbar d-flex">
        <div
          class="toolbar_items"
          v-if="provinces"
        >
          <span class="dropdown-label">Provinsi </span>
          <v-select
            v-if="role === ROLES.PublicHealthService_Regency || role === ROLES.PublicHealthService_Province"
            placeholder="Provinsi"
            dense
            disabled
            item-text="provinsi_name"
            item-value="provinsi_id"
            v-model="selected_province_id"
            @change="getCities"
            :items="provinces"
            hide-details="auto"
            outlined
            class="mt-1"
          ></v-select>
          <v-select
            v-else
            placeholder="Provinsi"
            dense
            item-text="provinsi_name"
            item-value="provinsi_id"
            v-model="selected_province_id"
            @change="getCities"
            :items="provinces"
            hide-details="auto"
            outlined
            class="mt-1"
          ></v-select>
        </div>
        <div class="toolbar_items">
          <span class="dropdown-label">Kabupaten/Kota</span>
          <v-select
            v-if="cities"
            placeholder="Kabupaten/Kota"
            dense
            :disabled="role === ROLES.PublicHealthService_Regency"
            item-text="kabupaten_name"
            item-value="kabupatenKota_id"
            v-model="selected_regency_id"
            :items="cities"
            hide-details="auto"
            outlined
            class="mt-1"
          ></v-select>
          <v-select
            v-else
            placeholder="Kabupaten/Kota"
            dense
            disabled
            hide-details="auto"
            outlined
          ></v-select>
        </div>
        <div class="toolbar_items">
          <span class="dropdown-label">Tahun - Bulan</span>
          <v-menu
            ref="menu"
            v-model="menu_date"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                append-icon="mdi-calendar"
                placeholder="--- ------"
                readonly
                outlined
                dense
                hide-details
                v-bind="attrs"
                v-on="on"
                class="mt-1"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              type="month"
              color="primary"
              no-title
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="menu = false"
              > Cancel </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.menu.save(date)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </div>
        <div class="align-self-end">
          <v-btn
            class="mx-2 rounded-lg custom_shadow"
            color="primary"
            @click="fetchReportsWithReset"
            height="40px"
            dark
            min-width="10px"
          >
            <v-icon>mdi-arrow-right</v-icon>
          </v-btn>
        </div>
      </div>

      <div style="width: 100%;">
        <v-row
          class="px-4 pt-8"
          style="width: 550px;"
        >
          <div
            style="width: 310px;"
            class="mr-1"
          >
            <v-text-field
              flat
              label="Cari Fasyankes"
              class="custom_field"
              background-color="#F1FBFC"
              solo
              @keydown="fetchReports"
              v-model="search_query"
              hide-details="auto"
              prepend-inner-icon="mdi-magnify"
              color="blue"
            ></v-text-field>
          </div>
          <div
            style="width: 141px;"
            class="ml-1"
          >
            <v-menu
              v-model="menu_limit"
              bottom
              :nudge-bottom="55"
              elevation="0"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-card
                  class="pa-3 d-flex align-center"
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  height="100%"
                >
                  <p class="mx-auto mr-2 ma-0 color_txt small_txt">
                    Show : {{ limit }}
                  </p>
                  <v-icon class="mx-auto">mdi-chevron-down</v-icon>
                </v-card>
              </template>
              <v-card
                class="popup_radius"
                outlined
                elevation="0"
              >
                <v-list>
                  <v-list-item
                    link
                    @click="onUpdateLimit(10)"
                  >
                    <v-list-item-title>10</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    link
                    @click="onUpdateLimit(50)"
                  >
                    <v-list-item-title>50</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    link
                    @click="onUpdateLimit(100)"
                  >
                    <v-list-item-title>100</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>
          </div>
        </v-row>
      </div>

      <v-skeleton-loader
        type="table-tbody"
        v-if="is_loading"
      ></v-skeleton-loader>

      <div
        class="my-5"
        v-if="!is_loading && users_monthly_report"
      >
        <v-data-table
          :headers="headers"
          :items="users_monthly_report.data"
          class="elevation-0 color_txt"
          :items-per-page="limit"
          hide-default-footer
          :item-class="getRowClasses"
        >
          <template v-slot:[`item.full_name`]="{ item }">
            {{ item.full_name }}
          </template>
          <template v-slot:[`item.report_environment_health_key_updated_at`]="{ item }">
            <div v-if="item">
              {{ item.report_environment_health_key_updated_at ? $date(item.report_environment_health_key_updated_at).format("D MMMM YYYY") : '-'  }}
            </div>
          </template>
          <template v-slot:[`item.report_public_health_facility_waste_updated_at`]="{ item }">
            <div v-if="item">
              {{ item.report_public_health_facility_waste_updated_at ? $date(item.report_public_health_facility_waste_updated_at).format("D MMMM YYYY") : '-' }}
            </div>
          </template>
        </v-data-table>
      </div>
      <div
        v-if="last_page > 0"
        class="d-flex justify-space-between pa-3"
      >
        <div class="mx-auto bottom-bar">
          <div class="mx-auto d-flex align-center pa-2 round_paginate">
            <v-btn
              small
              min-width="10px"
              color="primary"
              dark
              class="pa-2 custom_shadow"
              @click="pagination('prev')"
            ><v-icon small>mdi-arrow-left</v-icon></v-btn>
            <div class="mx-3">
              <p class="ma-0 color_default">{{ page }} of {{ last_page }}</p>
            </div>
            <v-btn
              small
              min-width="10px"
              color="primary"
              dark
              class="pa-2 custom_shadow"
              @click="pagination('next')"
            ><v-icon small>mdi-arrow-right</v-icon></v-btn>
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { ROLES } from "../../../constants/constant";

export default {
  name: "TableTabMonthlyReport",
  computed: {
    ...mapState({
      role: (state) => state.role,
      profile: (state) => state.user.profile,
      users_monthly_report: (state) => state.report.users_monthly_report,
      last_page: (state) => state.report.users_monthly_report.last_page,
    }),
  },
  async mounted() {
    await this.getProvinces();
    if (this.profile.access_right === ROLES.PublicHealthService_Province) {
      this.selected_province_id = parseInt(this.profile.provinsi);
      this.selected_role = ROLES.PublicHealthService_Regency;
    }
    if (this.selected_province_id) {
      this.getCities(false);
    }

    this.fetchReports();
  },
  data() {
    return {
      ROLES,
      is_loading: false,
      users: [],
      provinces: [],
      cities: [],
      selected_province_id: null,
      selected_regency_id: null,
      date: this.$date().format("YYYY-MM"),
      search_query: "",
      page: 1,
      limit: 10,
      menu_date: false,
      menu_limit: false,
      headers: [
        {
          text: "Fasyankes",
          align: "start",
          value: "full_name",
        },
        {
          text: "Kesling Kunci",
          align: "center",
          value: "report_environment_health_key_updated_at",
          sortable: false,
        },
        {
          text: "Kelola Limbah",
          align: "center",
          value: "report_public_health_facility_waste_updated_at",
          sortable: false,
        },
      ],
    };
  },

  async mounted() {
    await this.getProvinces();
    if (this.profile.access_right === ROLES.PublicHealthService_Province) {
      this.selected_province_id = parseInt(this.profile.provinsi);
      this.selected_role = ROLES.PublicHealthService_Regency;
      await this.getCities();
    }

    if (this.profile.access_right === ROLES.PublicHealthService_Regency) {
      this.selected_province_id = parseInt(this.profile.provinsi);
      this.selected_regency_id = parseInt(this.profile.kota);
      this.selected_role = ROLES.PublicHealthService_Regency;
      await this.getCities();
    }

    this.fetchReports();
  },
  methods: {
    getProvinces() {
      this.$store.dispatch("region/getProvinces").then((data) => {
        this.provinces = data.data;
        const default_province = {
          provinsi_id: "",
          provinsi_name: "SEMUA (NASIONAL)",
        };
        this.provinces.unshift(default_province);
      });
    },
    getCities() {
      if (!this.selected_province_id || this.selected_province_id === "") {
        this.selected_regency_id = null;
        this.cities = [];
        return;
      }
      const payload = {
        provinsi_id: this.selected_province_id,
      };
      this.$store.dispatch("region/getCities", payload).then((data) => {
        let cities = data.data;
        if (cities?.length > 0) {
          const all_city = {
            kabupatenKota_id: "",
            kabupaten_name: "SEMUA (KABUPATEN/KOTA)",
          };
          cities?.unshift(all_city);
        }
        this.cities = cities;
      });
    },
    async fetchReports() {
      this.is_loading = true;
      const request_params = {
        page: this.page,
        limit: this.limit,
        search_query: this.search_query,
        date: this.date,
        province_id: this.selected_province_id,
        regency_id: this.selected_regency_id,
      };
      await this.$store.dispatch(
        "report/getUsersMonthlyReport",
        request_params
      );
      this.is_loading = false;
    },

    async fetchReportsWithReset() {
      this.page = 1;
      this.limit = 10;
      this.search_query = "";
      this.fetchReports();
    },
    onUpdateLimit(limit) {
      this.menu_limit = false;
      this.limit = limit;
      this.fetchReports();
    },
    getRowClasses(item) {
      let idx = this.users_monthly_report.data.indexOf(item);
      if (idx % 2 == 0) {
        return "strip_blue";
      }
    },
    pagination(type) {
      if (type === "prev") {
        if (this.page > 1) {
          this.page--;
          this.fetchReports();
        }
      } else if (type === "next") {
        if (this.page < this.last_page) {
          this.page++;
          this.fetchReports();
        }
      }
    },
  },
  watch: {
    selected_province_id() {
      if (
        this.selected_province_id &&
        this.selected_province_id !== "" &&
        this.role !== ROLES.PublicHealthService_Regency
      ) {
        this.selected_regency_id = null;
      }
    },
  },
};
</script>

<style scoped>
* {
  font-family: "Monserrat";
}

.v-btn__content {
  min-width: none !important;
  width: 30px !important;
}

.dropdown-label {
  font-weight: 500;
}

.round_paginate {
  height: max-content;
  border: 1px solid rgba(215, 215, 215, 1);
  border-radius: 10px;
  width: max-content;
}

.confirm {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #64ccb7;
  margin-right: 10px;
}

.repair {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #59b5cc;
  margin-right: 10px;
}

.sent {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #d7d7d7;
  margin-right: 10px;
}

.unsend {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #f4cb08;
  margin-right: 10px;
}

.nofill {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #d92b2a;
  margin-right: 10px;
}

.bottom-bar {
  width: calc(100% / 3);
}

.toolbar_items {
  width: calc(90% / 4);
  padding-right: 10px;
}
</style> 