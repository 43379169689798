<template>
  <div>
    <v-card class="radius_intab custom_card pa-5">
      <div class="toolbar d-flex">
        <div
          v-if="provinces"
          class="toolbar_items"
        >
          <span class="dropdown-label">Provinsi </span>
          <v-select
            v-if="role === ROLES.PublicHealthService_Regency || role === ROLES.PublicHealthService_Province"
            v-model="selected_province_id"
            placeholder="Provinsi"
            dense
            disabled
            item-text="provinsi_name"
            item-value="provinsi_id"
            :items="provinces"
            hide-details="auto"
            outlined
            class="mt-1"
            @change="getCities"
          />
          <v-select
            v-else
            v-model="selected_province_id"
            placeholder="Provinsi"
            dense
            item-text="provinsi_name"
            item-value="provinsi_id"
            :items="provinces"
            hide-details="auto"
            outlined
            class="mt-1"
            @change="getCities"
          />
        </div>
        <div class="toolbar_items">
          <span class="dropdown-label">Kabupaten/Kota</span>
          <v-select
            v-if="cities"
            v-model="selected_regency_id"
            placeholder="Kabupaten/Kota"
            dense
            :disabled="role === ROLES.PublicHealthService_Regency"
            item-text="kabupaten_name"
            item-value="kabupatenKota_id"
            :items="cities"
            hide-details="auto"
            outlined
            class="mt-1"
          />
          <v-select
            v-else
            placeholder="Kabupaten/Kota"
            dense
            disabled
            hide-details="auto"
            outlined
          />
        </div>
        <div class="toolbar_items">
          <span class="dropdown-label">Tahun - Bulan</span>
          <v-menu
            ref="menu"
            v-model="menu_date"
            :close-on-content-click="false"
            :return-value.sync="selected_date"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                v-model="selected_date"
                append-icon="mdi-calendar"
                placeholder="--- ------"
                readonly
                outlined
                dense
                hide-details
                v-bind="attrs"
                class="mt-1"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="selected_date"
              type="month"
              color="primary"
              no-title
              scrollable
            >
              <v-spacer />
              <v-btn
                text
                color="primary"
                @click="menu = false"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.menu.save(selected_date)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </div>
        <div class="align-self-end">
          <v-btn
            class="mx-2 rounded-lg custom_shadow"
            color="primary"
            height="40px"
            dark
            min-width="10px"
            @click="fetchTableDataWithReset"
          >
            <v-icon>mdi-arrow-right</v-icon>
          </v-btn>
        </div>
      </div>

      <div style="width: 100%;">
        <v-row
          class="px-4 pt-8"
          style="width: 550px;"
        >
          <div
            style="width: 310px;"
            class="mr-1"
          >
            <v-text-field
              v-model="search_query"
              flat
              label="Cari Dinkes"
              class="custom_field"
              background-color="#F1FBFC"
              solo
              hide-details="auto"
              prepend-inner-icon="mdi-magnify"
              color="blue"
              @keydown="fetchTableData"
            />
          </div>
          <div
            style="width: 141px;"
            class="ml-1"
          >
            <v-menu
              v-model="menu_limit"
              bottom
              :nudge-bottom="55"
              elevation="0"
            >
              <template #activator="{ on, attrs }">
                <v-card
                  class="pa-3 d-flex align-center"
                  outlined
                  v-bind="attrs"
                  height="100%"
                  v-on="on"
                >
                  <p class="mx-auto mr-2 ma-0 color_txt small_txt">
                    Show : {{ limit }}
                  </p>
                  <v-icon class="mx-auto">
                    mdi-chevron-down
                  </v-icon>
                </v-card>
              </template>
              <v-card
                class="popup_radius"
                outlined
                elevation="0"
              >
                <v-list>
                  <v-list-item
                    link
                    @click="onUpdateLimit(10)"
                  >
                    <v-list-item-title>10</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    link
                    @click="onUpdateLimit(50)"
                  >
                    <v-list-item-title>50</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    link
                    @click="onUpdateLimit(100)"
                  >
                    <v-list-item-title>100</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>
          </div>
        </v-row>
      </div>

      <v-skeleton-loader
        v-if="is_loading"
        type="table-tbody"
      />

      <div
        v-if="!is_loading && users"
        class="my-5"
      >
        <v-data-table
          :headers="headers"
          :items="users"
          class="elevation-0 color_txt"
          :items-per-page="limit"
          hide-default-footer
          :item-class="getRowClasses"
        >
          <template #[`item.full_name`]="{ item }">
            {{ item.full_name }}
          </template>
          <template #[`item.last_login_at`]="{ item }">
            <div v-if="item">
              {{ formatLastLoginAt(item.last_login_at) }}
            </div>
          </template>
        </v-data-table>
      </div>
      <div
        v-if="last_page > 0"
        class="d-flex justify-space-between pa-3"
      >
        <div class="mx-auto bottom-bar">
          <div class="mx-auto d-flex align-center pa-2 round_paginate">
            <v-btn
              small
              min-width="10px"
              color="primary"
              dark
              class="pa-2 custom_shadow"
              @click="pagination('prev')"
            >
              <v-icon small>
                mdi-arrow-left
              </v-icon>
            </v-btn>
            <div class="mx-3">
              <p class="ma-0 color_default">
                {{ page }} of {{ last_page }}
              </p>
            </div>
            <v-btn
              small
              min-width="10px"
              color="primary"
              dark
              class="pa-2 custom_shadow"
              @click="pagination('next')"
            >
              <v-icon small>
                mdi-arrow-right
              </v-icon>
            </v-btn>
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { ROLES } from "../../../constants/constant";

export default {
  name: "TableTabLoginAccesses",
  data() {
    return {
      ROLES,
      is_loading: false,
      users: [],
      provinces: [],
      cities: [],
      selected_province_id: null,
      selected_regency_id: null,
      selected_date: this.$date().format("YYYY-MM"),
      search_query: "",
      page: 1,
      last_page: 1,
      limit: 10,
      menu_date: false,
      menu_limit: false,
      headers: [
        {
          text: "Dinas Kesehatan",
          align: "start",
          value: "full_name",
        },
        {
          text: "Last Login",
          align: "start",
          value: "last_login_at",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    ...mapState({
      role: (state) => state.role,
      profile: (state) => state.user.profile,
    }),
  },
  async mounted() {
    await this.getProvinces();
    if (this.profile.access_right === ROLES.PublicHealthService_Province) {
      this.selected_province_id = parseInt(this.profile.provinsi);
      this.selected_role = ROLES.PublicHealthService_Regency;
    }
    if (this.selected_province_id) {
      this.getCities();
    } 
    this.fetchTableData();
  },
  methods: {
    getProvinces() {
      this.$store.dispatch("region/getProvinces").then((data) => {
        this.provinces = data.data;
        const default_province = {
          provinsi_id: "",
          provinsi_name: "SEMUA (NASIONAL)",
        };
        this.provinces.unshift(default_province);
      });
    },
    getCities() {
      this.selected_regency_id = null;
      const payload = {
        provinsi_id: this.selected_province_id,
      };
      this.$store.dispatch("region/getCities", payload).then((data) => {
        let cities = data.data;
        const all_city = {
          kabupatenKota_id: "",
          kabupaten_name: "SEMUA (KABUPATEN/KOTA)",
        };
        cities.unshift(all_city);
        this.cities = cities;
      });
    },
    async fetchTableData() {
      this.is_loading = true;
      const api_base_url = process.env.VUE_APP_API_BASE_URL;
      const config = {
        method: "get",
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.token}`,
        },
      };

      let request_url = new URL(`${api_base_url}/api/user-last-login`);
      if (this.limit) {
        request_url.searchParams.append("limit", this.limit);
      }

      if (this.page) {
        request_url.searchParams.append("page", this.page);
      }

      if (this.search_query) {
        request_url.searchParams.append("search_query", this.search_query);
      }

      let role = "";
      if (this.selected_role) {
        role = this.selected_role;
      } else if (this.role === this.ROLES.PublicHealthService_Province) {
        role = this.ROLES.PublicHealthService_Regency;
        this.selected_province_id = parseInt(this.profile.provinsi);
      } else if (
        this.role === this.ROLES.MinistryOfHealth ||
        this.role === this.ROLES.Administrator
      ) {
        if (this.selected_regency_id) {
          role = this.ROLES.PublicHealthService_Regency;
        } else {
          role = this.ROLES.PublicHealthService_Province;
        }
      }

      request_url.searchParams.append("role_type", role);

      if (this.selected_province_id) {
        request_url.searchParams.append(
          "province_id",
          this.selected_province_id
        );
      }

      if (this.selected_regency_id) {
        request_url.searchParams.append("regency_id", this.selected_regency_id);
      }

      if (this.selected_date) {
        request_url.searchParams.append("date", this.selected_date);
      }

      request_url = encodeURI(request_url);

      const res = await this.$store.dispatch(
        "user/getUsersLastLogin",
        request_url,
        config
      );
      this.users = res.data;
      this.page = res.current_page;
      this.total = res.total;
      this.last_page = res.last_page;

      this.is_loading = false;
    },
    async fetchTableDataWithReset() {
      this.page = 1;
      this.limit = 10;
      this.search_query = "";
      this.fetchTableData();
    },
    onUpdateLimit(limit) {
      this.menu_limit = false;
      this.limit = limit;
      this.fetchTableData();
    },
    getRowClasses(item) {
      let idx = this.users.indexOf(item);
      if (idx % 2 == 0) {
        return "strip_blue";
      }
    },
    pagination(type) {
      if (type === "prev") {
        if (this.page > 1) {
          this.page--;
          this.fetchTableData();
        }
      } else if (type === "next") {
        if (this.page < this.last_page) {
          this.page++;
          this.fetchTableData();
        }
      }
    },
    formatLastLoginAt(last_login_at) {
      if (last_login_at) {
        const last_login_month = this.$date(last_login_at).format("M");
        const last_login_year = this.$date(last_login_at).format("YYYY")
        const current_month = this.$date(this.selected_date).format("M");
        const current_year = this.$date(this.selected_date).format("YYYY");

        if ((last_login_month === current_month && last_login_year === current_year)) {
          return this.$date(last_login_at).format("D MMMM YYYY, HH:mm");
        }        
      }

      return '-';
    },
  },
};
</script>

<style scoped>
.v-btn__content {
  min-width: none !important;
  width: 30px !important;
}

.dropdown-label {
  font-weight: 500;
}

.round_paginate {
  height: max-content;
  border: 1px solid rgba(215, 215, 215, 1);
  border-radius: 10px;
  width: max-content;
}

.confirm {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #64ccb7;
  margin-right: 10px;
}

.repair {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #59b5cc;
  margin-right: 10px;
}

.sent {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #d7d7d7;
  margin-right: 10px;
}

.unsend {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #f4cb08;
  margin-right: 10px;
}

.nofill {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #d92b2a;
  margin-right: 10px;
}

.bottom-bar {
  width: calc(100% / 3);
}

.toolbar_items {
  width: calc(90% / 4);
  padding-right: 10px;
}
</style> 